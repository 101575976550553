import 'vanilla-cookieconsent';



// obtain plugin
var cc = initCookieConsent();

var cookieconsent = false;
// run plugin with your configuration
cc.run({
    current_lang: 'de',
    autoclear_cookies: true,                   // default: false
    page_scripts: true,                        // default: false
    force_consent: true,


    gui_options: {
        consent_modal: {
            layout: 'cloud',               // box/cloud/bar
            position: 'center center',     // bottom/middle/top + left/right/center
            transition: 'slide',           // zoom/slide
            swap_buttons: false            // enable to invert buttons
        },
        settings_modal: {
            layout: 'cloud',                 // box/bar
            // position: 'left',           // left/right
            transition: 'slide'            // zoom/slide
        }
    },

    // mode: 'opt-in'                          // default: 'opt-in'; value: 'opt-in' or 'opt-out'
    // delay: 0,                               // default: 0
    // auto_language: null                     // default: null; could also be 'browser' or 'document'
    // autorun: true,                          // default: true
    // force_consent: false,                   // default: false
    // hide_from_bots: false,                  // default: false
    // remove_cookie_tables: false             // default: false
    // cookie_name: 'cc_cookie',               // default: 'cc_cookie'
    // cookie_expiration: 182,                 // default: 182 (days)
    // cookie_necessary_only_expiration: 182   // default: disabled
    // cookie_domain: location.hostname,       // default: current domain
    // cookie_path: '/',                       // default: root
    // cookie_same_site: 'Lax',                // default: 'Lax'
    // use_rfc_cookie: false,                  // default: false
    // revision: 0,                            // default: 0

    onFirstAction: function(user_preferences, cookie){
        // callback triggered only once
    },

    onAccept: function (cookie) {
        // ...
        cookieconsent = cookie.categories;
        window.dataLayer.push({'event': 'CookiesAccepted', 'cookie': cookie.categories});
    },

    onChange: function (cookie, changed_preferences) {
        // ...
        cookieconsent = cookie.categories;
        window.dataLayer.push({'event': 'CookiesAccepted', 'cookie': cookie.categories});
    },

    languages: {
        'de': {
            consent_modal: {
                title: 'Wir nutzen Cookies!',
                description: 'Hey, diese Website nutzt Cookies, Pixels und vergleichbare Technologien (zusammen der Einfachheit halber „Cookies“ genannt), auch von Dritten, um unsere Dienste anzubieten, stetig zu verbessern und Inhalte unserer Website, Social Media und Partnerseiten anzuzeigen (s.a. unsere Datenschutzerklärung). Du hast die Wahl, ob nur notwendige Cookies verwendet werden sollen, oder ob du darüber hinaus weitere Cookies akzeptieren möchtest. <button type="button" data-cc="c-settings" class="cc-link">Lass es mich aussuchen.</button>',
                primary_btn: {
                    text: 'Alle akzeptieren.',
                    role: 'accept_all'              // 'accept_selected' or 'accept_all'
                },
                secondary_btn: {
                    text: 'Notwendigen akzeptieren.',
                    role: 'accept_necessary'        // 'settings' or 'accept_necessary'
                }
            },
            settings_modal: {
                title: 'Cookie Einstellungen',
                save_settings_btn: 'Einstellungen speichern',
                accept_all_btn: 'Alle Akzeptieren',
                reject_all_btn: 'Nur die Notwendigen Akzeptieren',
                close_btn_label: 'Schließen',
                blocks: [
                    {
                        title: 'Über die Cookies 📢',
                        description: 'Wir nutzen Cookies um Einstellungen auf dieser Seite, die Sie gemacht haben zu speichern. Außerdem nutzen wir Cookies um die Seite und ihr Verhalten auf der Seite zu analysieren um diese dann besser für Sie gestallten zu können. Für weitere Informationen können Sie sich unsere <a href="./Datenschutz.html" class="cc-link">Datenschutzerklärung</a> durchlesen.'
                    }, {
                        title: 'Notwendige Cookies',
                        description: 'Diese Cookies müssen angenommen werden, ansonsten funktioniert die Website nicht. Mit diesen Cookies wird beispielsweise gespeichert, welche Einstellungen Sie hier vorgenommen haben.',
                        toggle: {
                            value: 'necessary',
                            enabled: true,
                            readonly: true          // cookie categories with readonly=true are all treated as "necessary cookies"
                        }
                    }, {
                        title: 'Performance and Analyse cookies',
                        description: 'Diese Cookies dienen zur Analyse der Performence um für Sie die Website besser zu gestallten.',
                        toggle: {
                            value: 'analytics',     // your cookie category
                            enabled: false,
                            readonly: false
                        }
                    }, {
                        title: 'Advertisement / Werbung',
                        description: 'Mit diesen Cookies können wir speichern welche Seiten Sie sich angeschaut haben, um Ihnen personalisierte Werbung anzuzeigen.',
                        toggle: {
                            value: 'targeting',
                            enabled: false,
                            readonly: false
                        }
                    }, {
                        title: 'Weiter Informationen',
                        description: 'Für weitere Informationen bezüglich Cookies und der Anwendung auf der Seite wenden Sie sich bitte an <a class="cc-link" href="emailto:kontakt@vonreyher.com">kontakt@vonreyher.com</a>.',
                    }
                ]
            }
        }
    }
});